import { Customer } from '../Customer';
import { PaymentSummary, MethodData, APIRequest, threeDSPaymentInfo } from '../Types';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { DigitalWallet } from './DigitalWallet';

import GooglePayJS = google.payments.api;

type GooglePayButtonColor = 'black' | 'white' | 'default' | 'undefined';

export class GooglePay implements DigitalWallet {
    private controller: MerchantWarriorController;
    private data: any;
    private client: GooglePayJS.PaymentsClient;
    private summary: PaymentSummary;
    private supportedMethods: string;
    private mountedButton: any;

    constructor(controller: MerchantWarriorController, summary: PaymentSummary, client: GooglePayJS.PaymentsClient, data: any) {
        this.supportedMethods = 'https://google.com/pay'; // For PR API
        this.data = data;
        this.data.merchantInfo.merchantOrigin = location.hostname;

        this.summary = summary;

        this.controller = controller;
        this.client = client;

        this.mountedButton = null;

        this.data.transactionInfo = this.formatSummary(summary);
    }

    public static create(controller: MerchantWarriorController, summary: PaymentSummary): Promise<GooglePay> {
        return GooglePay.loadGooglePayJS().then((client: typeof GooglePayJS.PaymentsClient) => {

            const environment: ('PRODUCTION' | 'TEST') = controller.getOptions().environment == 'production' ? 'PRODUCTION' : 'TEST';

            const allowedAuthMethods = summary.googlePay?.allowedAuthMethods ?? ["PAN_ONLY", "CRYPTOGRAM_3DS"];
            let allowedCardNetworks: GooglePayJS.CardNetwork[] =  this.getAllowedCardNetworks(summary);

                        
            var onPaymentDataChanged = summary.googlePay?.onPaymentDataChanged;
            var onPaymentDataChangedFunction = function(onPaymentDataChangedData:any): Promise<GooglePayJS.PaymentDataRequestUpdate> | GooglePayJS.PaymentDataRequestUpdate {
                const originalPaymentDataRequest: GooglePayJS.PaymentDataRequestUpdate = {newTransactionInfo: {currencyCode: controller.getInfo().currency,totalPrice: MerchantWarriorController.getTotalPrice(summary),totalPriceStatus: 'FINAL',totalPriceLabel: summary.total.label}};
                
                if (typeof onPaymentDataChanged === 'function') {
                   var paymentDataRequestUpdate =  onPaymentDataChanged(onPaymentDataChangedData);
                   if(isGooglePayPaymentDataRequestUpdate(paymentDataRequestUpdate)){
                        if(paymentDataRequestUpdate.newTransactionInfo){
                            summary.total.amount = paymentDataRequestUpdate.newTransactionInfo.totalPrice || MerchantWarriorController.getTotalPrice(summary);
                            summary.total.label = paymentDataRequestUpdate.newTransactionInfo.totalPriceLabel || summary.total.label;
                            if(paymentDataRequestUpdate.newTransactionInfo.currencyCode != controller.getInfo().currency){
                                console.log('Updated currency (' + paymentDataRequestUpdate.newTransactionInfo.currencyCode + ') does not match with Merchant Currency (' + controller.getInfo().currency + ').')
                            }
                        }
                        return Promise.resolve(paymentDataRequestUpdate);
                   }else{
                        return Promise.resolve(originalPaymentDataRequest);
                   }  
                }
                return Promise.resolve(originalPaymentDataRequest);
          }

          var onPaymentAuthorized = summary.googlePay?.onPaymentAuthorized;
          var onPaymentAuthorizedFunction = function(onPaymentAuthorizedData:any) : Promise<GooglePayJS.PaymentAuthorizationResult> | GooglePayJS.PaymentAuthorizationResult{
            //This event is triggered after the user presses the authorization button but before the actual payment authorization occurs.
            const authorizationResult : GooglePayJS.PaymentAuthorizationResult = {
                transactionState: 'SUCCESS',
            };
            if (typeof onPaymentAuthorized === 'function') {
                var paymentAuthorizationResult =  onPaymentAuthorized(onPaymentAuthorizedData);
                if(isGooglePayPaymentAuthorizationResult(paymentAuthorizationResult)){
                    return Promise.resolve(paymentAuthorizationResult);
                }else{
                    return Promise.resolve(authorizationResult);
                }
            }
            return Promise.resolve(authorizationResult);
          }

            const callbackIntents = [];

            if(typeof onPaymentDataChanged === 'function'){
                //If PaymentDataRequest.callbackIntents contains SHIPPING_OPTION, PaymentDataRequest.shippingOptionRequired must be set to true!
                if(summary.googlePay?.shippingOptionRequired)callbackIntents.push('SHIPPING_OPTION');
                if(summary.googlePay?.shippingAddressRequired)callbackIntents.push('SHIPPING_ADDRESS');
            }
            // payment-authorized event 
            if(typeof onPaymentAuthorized === 'function')callbackIntents.push('PAYMENT_AUTHORIZATION');

            const data = {
                environment: environment,
                apiVersion: 2,
                apiVersionMinor: 0,
                merchantInfo: controller.getInfo().wallets.googlepay.merchantInfo,
                callbackIntents: callbackIntents,
                allowedPaymentMethods: [{
                    type: 'CARD',
                    parameters: {
                        //allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                        // allowedAuthMethods: ["PAN_ONLY"],
                        allowedAuthMethods: allowedAuthMethods,
                        allowedCardNetworks: allowedCardNetworks,
                        billingAddressRequired: summary.googlePay?.billingAddressRequired,
                        billingAddressParameters:summary.googlePay?.billingAddressParameters
                    },
                    tokenizationSpecification: { 
                        type: 'PAYMENT_GATEWAY',
                        parameters: {
                            'gatewayMerchantId': controller.getMerchantUUID(),
                            'gateway': 'merchantwarrior',
                        }
                    }
                }],
                emailRequired: summary.googlePay?.emailRequired,
                shippingAddressRequired: summary.googlePay?.shippingAddressRequired,
                shippingAddressParameters: summary.googlePay?.shippingAddressParameters,
                shippingOptionRequired: summary.googlePay?.shippingOptionRequired
            }
            const allowedPaymentMethods: GooglePayJS.IsReadyToPayPaymentMethodSpecification[] = [{
                type: 'CARD',
                parameters: {
                    allowedAuthMethods: allowedAuthMethods,
                    allowedCardNetworks: allowedCardNetworks
                },
            }];

            const readyToPayRequest: GooglePayJS.IsReadyToPayRequest = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: allowedPaymentMethods,
            }
            // GooglePayJS.PaymentDataCallbacks

            var clientData = {
                environment: environment,
                merchantInfo: controller.getInfo().wallets.googlepay.merchantInfo,
                paymentDataCallbacks: {},
            }

            if(onPaymentDataChanged)Object.assign(clientData.paymentDataCallbacks, {onPaymentDataChanged:onPaymentDataChangedFunction})
            if(onPaymentAuthorized)Object.assign(clientData.paymentDataCallbacks, {onPaymentAuthorized:onPaymentAuthorizedFunction})

            const paymentClient = new client(clientData);

            return paymentClient.isReadyToPay(readyToPayRequest).then((response: GooglePayJS.IsReadyToPayResponse) => {
                if (response.result === true) {
                    const wallet = new GooglePay(controller, summary, paymentClient, data);
                    return wallet;
                }

                throw new Error('Google Pay Unavailable');
            });
        });
    }

    private static loadGooglePayJS(): Promise<typeof GooglePayJS.PaymentsClient> {
        return new Promise<typeof GooglePayJS.PaymentsClient>((resolve, reject) => {
            if (window?.google?.payments?.api?.PaymentsClient) {
                const google: typeof GooglePayJS.PaymentsClient = window.google.payments.api.PaymentsClient;
                resolve(google);
                return;
            }

            const existing = document.getElementsByClassName('mw-gp');
            let script: HTMLScriptElement;

            if (existing.length > 0) {
                script = <HTMLScriptElement> existing.item(0);
            } else {
                script = document.createElement('script');
                script.src = 'https://pay.google.com/gp/p/js/pay.js';
                script.type = 'text/javascript';
                script.classList.add('mw-gp');
                document.head.appendChild(script);
            }

            script.addEventListener('load', () => {
                const google: typeof GooglePayJS.PaymentsClient = window.google.payments.api.PaymentsClient;
                resolve(google);
                return;
            });
        });
    }

    private formatSummary(summary: PaymentSummary): GooglePayJS.TransactionInfo {
        const info = this.controller.getInfo();

        let totalPrice: string;
        if (typeof summary.total.amount == 'string') {
            totalPrice = summary.total.amount;
        } else {
            totalPrice = summary.total.amount.value;
        }



        let transactionInfo: GooglePayJS.TransactionInfo = {
            currencyCode: info.currency,
            totalPrice: totalPrice,
            totalPriceStatus: 'FINAL',
            totalPriceLabel: summary.total.label
        }

        if(typeof summary.googlePay?.onPaymentDataChanged === 'function' && summary.googlePay?.shippingAddressRequired){
            transactionInfo.totalPriceStatus = 'ESTIMATED';
        }


        return transactionInfo;
    }

    public getPayButton(): Promise<HTMLElement> {
        return this.getButton((evt: Event) => {
            this.startPayment();
        });
    }

    public getAddButton(): Promise<HTMLElement> {
        return this.getButton((evt: Event) => {
            this.startAddCard();
        }, false);
    }

    private getButton(callback: (evt: Event) => void, pay: boolean = true): Promise<HTMLElement> {
        let color: GooglePayButtonColor = 'default';
        if (this.summary.style == 'light') {
            color = 'white';
        } else { // Default dark if not set
            color = 'black';
        }

        // Button Options
        let buttonText:any = this.summary.googlePay?.buttonText || 'buy';
        let buttonLocale:string = this.summary.googlePay?.buttonLocale || 'en';

        // Create Button
        const button = this.client.createButton(
        {
            onClick: callback,
            buttonSizeMode: 'fill',
            buttonColor: color,
            buttonType: buttonText,
            buttonLocale: buttonLocale
        });

        this.mountedButton = button;

        // Button Styling
        button.style.height = this.summary.googlePay?.buttonHeight || '45px';
        !(this.summary.googlePay?.buttonWidth === undefined) ? button.style.width = this.summary.googlePay.buttonWidth : '';
        
        const margin: string = '5px';
        //button.style.marginTop = margin;
        //button.style.marginBottom = margin;
        return Promise.resolve(button);
    }

    private startPayment(): void {
        new Promise<Customer>((resolve: any, reject: any) => {
            if (this.summary.customer) {
                resolve(this.summary.customer);
                return;
            }
            this.controller.emit('request-customer', resolve, reject); // Prompt MerchantWarriorController object for customer
        }).then((customer: Customer) => {
            this.summary.customer = customer;
            return this.client.loadPaymentData(this.data)
        }).then((paymentData: any) => {
            this.controller.emit('payment-authorized', paymentData);

            var onSessionAuthorizedFunction = this.summary.googlePay?.onSessionAuthorized;
            if (typeof onSessionAuthorizedFunction === 'function') {
                var googlePaySessionAuthorizedFlag = onSessionAuthorizedFunction(paymentData);
                if(googlePaySessionAuthorizedFlag === "FAILURE"){
                    throw new Error('Google Pay FAILURE');
                    return;
                }else if(googlePaySessionAuthorizedFlag === "SKIP_AUTH_APPROVE"){
                    throw new Error('Google Pay SKIP_AUTH_APPROVE');
                    return;
                }
            }
            
            //Attempt to Authenticate 3DS on top of GooglePay
            if(this.summary.googlePay?.threeDS){
                if(typeof this.summary.googlePay?.threeDSAddition?.loading == 'function') {
                    this.summary.googlePay?.threeDSAddition?.loading();
                }else{
                    //Default Loading Animation style
                    var googlePayButton = this.mountedButton.querySelector('button');
                    const button = document.createElement('input');
                    button.type = 'button';
                    button.id = "GooglePay-Processing";
                    button.value = "Processing, please wait...";
                    button.style.height = googlePayButton.offsetHeight || "45px";
                    button.style.width = '100%';
                    button.style.borderRadius = '4px';
                    button.style.border = '1px solid transparent';
                    button.style.fontSize = '16px';
                    button.style.fontWeight = '400';
                    button.style.backgroundColor = "#3c4043";
                    button.style.color = "#FFFFFF" ;
                    googlePayButton.parentNode.appendChild(button)
                    googlePayButton.style.display = 'none';
                }

                // Create a promise wrapper around the mwCallback function
                const callbackPromise = new Promise((resolveCallback, rejectCallback) => {
                    const summary: PaymentSummary = this.summary; // Store this.summary in a local variable
                    const mountedButton = this.mountedButton

                    if(this.mountedButton.parentNode.querySelector('div#threeds-container'))
                        this.mountedButton.parentNode.removeChild(this.mountedButton.parentNode.querySelector('div#threeds-container'));

                    let tdsV2threedscontainer = document.createElement("div");
                    tdsV2threedscontainer.id = "threeds-container";
                    //Add Challenge Window beside GooglePay Button
                    this.mountedButton.parentNode.appendChild(tdsV2threedscontainer);

                    var paymentInfo: threeDSPaymentInfo = {
                        transactionInfo:{
                            transactionProduct: summary.total.label,
                            transactionAmount: MerchantWarriorController.getTotalPrice(summary),
                            transactionCurrency: this.data.transactionInfo.currencyCode,
                            cardInfo: {digitalWalletToken: paymentData.paymentMethodData.tokenizationData.token}, //Potentially we could optimize it into server-cache
                        },
                        transactionProduct: this.data.transactionInfo.totalPriceLabel,
                        customerName: (summary.customer?.name) ? summary.customer?.name : "",
                        customerEmail: (summary.customer?.email) ? summary.customer?.email : "",
                        customerPhone: (summary.customer?.phone) ? summary.customer?.phone : "",
                        billingAddress: {
                            billAddrLine1 : (summary.customer?.address) ? summary.customer?.address : "",
                            billAddrCity: (summary.customer?.city) ? summary.customer?.city : "",
                            billAddrState : (summary.customer?.state) ? summary.customer?.state : "",
                            billAddrPostCode :(summary.customer?.postCode) ? summary.customer?.postCode : "",
                            billAddrCountry : (summary.customer?.country) ? summary.customer?.country : "",
                        },
                        threeDS: {
                            notifyURL: false,
                        }
                    }

                    if(this.summary.googlePay?.infoUpdate){
                        let custoemrInfo = this.summary.customer
                        let infoUpdatePreference = this.summary.googlePay?.infoUpdatePreference || 'billingAddress';
                        if(infoUpdatePreference == 'billingAddress' && paymentData.paymentMethodData && paymentData.paymentMethodData.info && paymentData.paymentMethodData.info.billingAddress){
                            custoemrInfo = updateCustomerInfo(paymentData.paymentMethodData.info.billingAddress, this.summary.customer);
                        }else if(infoUpdatePreference == 'shippingAddress' && paymentData.shippingAddress){
                            custoemrInfo = updateCustomerInfo(paymentData.shippingAddress, this.summary.customer);
                        }
                        if(custoemrInfo?.address)paymentInfo.billingAddress.billAddrLine1 = custoemrInfo?.address;
                        if(custoemrInfo?.city)paymentInfo.billingAddress.billAddrCity = custoemrInfo?.city;
                        if(custoemrInfo?.state)paymentInfo.billingAddress.billAddrState = custoemrInfo?.state;
                        if(custoemrInfo?.postCode)paymentInfo.billingAddress.billAddrPostCode = custoemrInfo?.postCode;
                        if(custoemrInfo?.country)paymentInfo.billingAddress.billAddrCountry = custoemrInfo?.country;
                        if(custoemrInfo?.name)paymentInfo.customerName = custoemrInfo?.name;
                    }
                    
                    let tdsManager = this.controller.createTDSManager(paymentInfo);
                    if(summary.googlePay?.threeDSAddition?.loading) {
                        tdsManager.loading = summary.googlePay?.threeDSAddition?.loading;
                    }
                    if(summary.googlePay?.threeDSAddition?.loaded) {
                        tdsManager.loaded = summary.googlePay?.threeDSAddition?.loaded;
                    }
                    tdsManager.checkTDS();
                    tdsManager.mwCallback = function(results) { 

                        if(summary.googlePay?.threeDSAddition?.loaded) {
                            summary.googlePay?.threeDSAddition?.loaded()
                        }else{
                            //Default Loaded Animation style
                            var googlePayButton = mountedButton.querySelector('button');
                            googlePayButton.style.display = 'block';
                            document.getElementById("GooglePay-Processing")?.remove();
                            document.getElementById("threeds-container")?.remove();
                        }
                        const data = {
                            digitalWalletToken: paymentData.paymentMethodData.tokenizationData.token,
                            tdsResults: results
                        };
                        resolveCallback(data); // Resolve the callback promise with the desired data
                    };
                });
                return callbackPromise;
            }else{
                return paymentData;
            }

        }).then((paymentData: any) => {

            var paymentDataDigitalWalletToken = "";
            if(paymentData.tdsResults) paymentDataDigitalWalletToken = paymentData.digitalWalletToken; 
            else paymentDataDigitalWalletToken = paymentData.paymentMethodData.tokenizationData.token,
            this.controller.emit('payment-prepare');
            const processCardRequest: APIRequest = {
                method: 'processCard',
                transactionProduct: this.summary.total.label,
                transactionAmount: MerchantWarriorController.getTotalPrice(this.summary),
                transactionCurrency: this.data.transactionInfo.currencyCode,
                digitalWalletToken: paymentDataDigitalWalletToken
            }

            if(this.summary.transaction){
                if(this.summary.transaction.transactionReferenceID) processCardRequest.transactionReferenceID = this.summary.transaction.transactionReferenceID;
                if(this.summary.transaction.storeID) processCardRequest.storeID = this.summary.transaction.storeID;
                if(this.summary.transaction.custom1) processCardRequest.custom1 = this.summary.transaction.custom1;
                if(this.summary.transaction.custom2) processCardRequest.custom2 = this.summary.transaction.custom2;
                if(this.summary.transaction.custom3) processCardRequest.custom3 = this.summary.transaction.custom3;
                if(this.summary.transaction.transactionProduct) processCardRequest.transactionProduct = this.summary.transaction.transactionProduct;
            }

            if (this.summary.addCard) {
                processCardRequest.addCard = 1;
            }

            if (paymentData.tdsResults && paymentData.tdsResults.threeDSToken) {
                processCardRequest.threeDSToken = paymentData.tdsResults.threeDSToken;
            }

            let custoemrInfo = this.summary.customer;
            if(this.summary.googlePay?.infoUpdate){
                let infoUpdatePreference = this.summary.googlePay?.infoUpdatePreference || 'billingAddress';
                if(infoUpdatePreference == 'billingAddress' && paymentData.paymentMethodData && paymentData.paymentMethodData.info && paymentData.paymentMethodData.info.billingAddress){
                    custoemrInfo = updateCustomerInfo(paymentData.paymentMethodData.info.billingAddress, this.summary.customer);
                }else if(infoUpdatePreference == 'shippingAddress' && paymentData.shippingAddress){
                    custoemrInfo = updateCustomerInfo(paymentData.shippingAddress, this.summary.customer);
                }
                if(paymentData.email && custoemrInfo)custoemrInfo.email = paymentData.email;
            }

            return this.controller.makeRequest(processCardRequest, custoemrInfo);

        }).then((result: any) => {
            let status: boolean = false;
            if (result.responseCode == 0) {
                status = true;
            }
            this.controller.emit('payment-complete', status, result);
        }).catch((error: any) => {

            const ignoredErrors = ['Google Pay FAILURE', 'Google Pay SKIP_AUTH_APPROVE'];
            //Make onSessionAuthorizedFunction behave consistently
            if (error instanceof Error && ignoredErrors.includes(error.message)) {
                this.controller.emit('payment-complete', false, null, error.message);
            }else{
                this.controller.emit('payment-complete', false, null, error);
            }
        });
    }

    private startAddCard(): void {
        this.client.loadPaymentData(this.data).then((paymentData: any) => {
            const info = this.controller.getInfo();
            const processCardRequest: APIRequest = {
                method: 'addCard',
                digitalWalletToken: paymentData.paymentMethodData.tokenizationData.token
            }

            return this.controller.makeRequest(processCardRequest, this.summary.customer);
        }).then((result: any) => {
            let status: boolean = false;
            if (result.responseCode == 0) {
                status = true;
            }
            this.controller.emit('card-added', status, result);
        }).catch((error: any) => {
            this.controller.emit('card-added', false, null, error);
        });
    }
    public getMethodData(): Promise<MethodData> {
        const methodData = {
            supportedMethods: this.supportedMethods,
            data: this.data
        };

        return Promise.resolve(methodData);
    }

    private static getAllowedCardNetworks(summary:PaymentSummary) : GooglePayJS.CardNetwork[]{

        let allowedCardNetworks: GooglePayJS.CardNetwork[];
        if (summary.googlePay?.allowedCardNetworks) {
            allowedCardNetworks = summary.googlePay?.allowedCardNetworks.map((str) => str.toUpperCase()) as GooglePayJS.CardNetwork[];
        } else {
            allowedCardNetworks = ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"];
        }

        return allowedCardNetworks;
    }
}

const isGooglePayPaymentDataRequestUpdate = (obj: any): obj is GooglePayJS.PaymentDataRequestUpdate => {
    if(!obj)return false;
    const allowedProperties: Record<string, boolean> = {
        newTransactionInfo: true,
        newShippingOptionParameters: true,
        newOfferInfo: true,
        error: true,
    };
    const keys = Object.keys(obj);
    for (const key of keys) {
        if (!allowedProperties[key]) {
            return false;
        }
    }
    return true;
};

const isGooglePayPaymentAuthorizationResult = (obj: any): obj is GooglePayJS.PaymentAuthorizationResult => {
    if(!obj)return false;
    const allowedProperties: Record<string, boolean> = {
        error: true,
        transactionState: true,
    };
    const keys = Object.keys(obj);
    for (const key of keys) {
        if (!allowedProperties[key]) {
            return false;
        }
    }
    return true;
};

const updateCustomerInfo = (googleAddress: GooglePayJS.Address, originalCustomer : Customer | undefined) : Customer => {
    //We could potentially update email as well, although email is not in address package
    let updatedCustomer: Customer;
    if(originalCustomer){
        updatedCustomer= {
            name: googleAddress.name || originalCustomer.name,
            country: googleAddress.countryCode || originalCustomer.country,
            state: googleAddress.administrativeArea || originalCustomer.state,
            city: googleAddress.locality || originalCustomer.city,
            address: googleAddress.address1 || originalCustomer.address,
            postCode: googleAddress.postalCode || originalCustomer.postCode,
            phone: googleAddress.phoneNumber || originalCustomer.phone,
            email: originalCustomer.email,
            ip: originalCustomer.ip,
        };
    }else{
       updatedCustomer= {
            name: googleAddress.name || '',
            country: googleAddress.countryCode,
            state: googleAddress.administrativeArea ,
            city: googleAddress.locality,
            address: googleAddress.address1 || '',
            postCode: googleAddress.postalCode,
            phone: googleAddress.phoneNumber,
        };
    }
    return updatedCustomer;
}