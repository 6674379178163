import { Customer } from './Customer';

type StyleOptions = 'light' | 'dark'; // Default Dark
type ApplePayContactField = 'email' | 'name' | 'phone' | 'postalAddress' | 'phoneticName';

export type SimplePaymentItem = {
    amount: string,
    label: string,
    pending?: boolean
}
export type LineItem = SimplePaymentItem | PaymentItem;

export type PaymentSummary = {
    total: LineItem,
    style?: StyleOptions
    customer?: Customer,
    requestCustomerDetails?: boolean, // Default false
    items?: LineItem[],
    addCard?: boolean,
    group?: boolean,
    wallets?: string[],
    applePay?: ApplePay,
    googlePay?: GooglePay, //Default ["PAN_ONLY", "CRYPTOGRAM_3DS"]
    transaction?: Transaction,
    recurring?: Recurring,
    payframe?: PayframeOptions,
}

export type GooglePay = {
    allowedAuthMethods: ["CRYPTOGRAM_3DS"] | ["PAN_ONLY"] | ["PAN_ONLY", "CRYPTOGRAM_3DS"],
    buttonText?: string,
    buttonLocale?: string,
    buttonHeight?: string,
    buttonWidth?: string,
    marginTop?: string,
    marginBottom?: string,
    marginLeft?: string,
    marginRight?: string,
    iframe?: boolean,
    allowedCardNetworks?: string[],
    threeDS?: boolean,
    threeDSAddition?: {
        loading?: any,
        loaded?: any,
    },
    billingAddressRequired?: boolean,
    infoUpdate?: true,
    infoUpdatePreference?: string,
    billingAddressParameters?: {
        format?: string,
        phoneNumberRequired?: boolean,
    },
    emailRequired?: boolean,
    shippingAddressRequired?: boolean,
    shippingAddressParameters?: {
        allowedCountryCodes?: string[],
        phoneNumberRequired?: boolean,
    },
    shippingOptionRequired ?: boolean,
    onSessionAuthorized?: any,
    onPaymentDataChanged?: any,
    onPaymentAuthorized?: any,
}

export type ApplePay = {
    buttonText?: string,
    buttonHeight?: string,
    buttonWidth?: string,
    marginTop?: string,
    marginBottom?: string,
    marginLeft?: string,
    marginRight?: string,
    buttonstyle?: string,
    locale?: string,
    iframe?: boolean,
    allowedCardNetworks?: string[],
    builtFrom?: string,
    requiredBillingContactFields?: ApplePayContactField[],
    infoUpdate?: boolean,
    infoUpdatePreference?: string,
    requiredShippingContactFields?: ApplePayContactField[],
    shippingMethods?: any,
    shippingType?: 'shipping' | 'delivery' | 'storePickup' | 'servicePickup',
    supportedCountries?: string[],
    onshippingcontactselected?: any,
    onshippingmethodselected?: any,
    onSessionAuthorized?: any,
    buttonElement?: boolean,
    crossPlatform?: boolean,
}

export type DynamicPaymentItem = {
    amount: string,
    label: string,
    currency: string,
    pending?: boolean
}

export type Transaction = {
    transactionProduct?: string,
    transactionReferenceID?: string,
    transactionCurrency?: string,
    currency?: string,
    storeID?: string,
    custom1?: string,
    custom2?: string,
    custom3?: string,
}

export type Recurring = {
    recurringPaymentStartDate?: string,
    recurringPaymentEndDate?: string,
    recurringPaymentIntervalUnit?: 'year' | 'month' | 'day' | 'hour' | 'minute',
    recurringPaymentIntervalCount?: number,
    managementURL?: string
}
export type AddCardSummary = {
    max: string, // Maximum Chargeable Amount
    wallets?: string[],
    group?: boolean,
    text?: string, // Used for basic-card
    style?: StyleOptions,
}

export type MethodData = {
    supportedMethods: string,
    data: any
}

export type APIRequest = {
    method: string,
    [key: string]: any
}

export type MerchantInfo = {
    name: string,
    country: string,
    currency: string,
    wallets: {
        [key: string]: any
    },
    uuid?: string
}

export type threeDSPaymentInfo = {
    transactionInfo:{
        transactionProduct: string,
        transactionAmount: string,
        transactionCurrency: string,
        originalCurrency?: string,
        originalAmount?: string,
        tdsDCC?: string,
        cardInfo:{
            paymentCardNumber?: string,
            paymentCardExpiry?: string,
            paymentCardName?: string,
            paymentCardCSC?: string,
            payframeToken?: string,
            payframeKey?: string,
            cardID?: string,
            cardKey?: string
            digitalWalletToken?: string
        },
    },
    transactionProduct: string,
    customerName: string,
    customerPhone: string,
    customerEmail: string,
    billingAddress: {
        billAddrLine1 : string,
        billAddrCity: string,
        billAddrState : string,
        billAddrPostCode : string,
        billAddrCountry : string,
    },
    threeDS:{
        notifyURL: string | boolean,
        threeDSRequestorAuthenticationInd?: string,
        threeDSRequestorChallengeInd?: string,
        challengeWindowSize?: string,
        merchantName?: string,
        source?: string,
        support?: string,
    }
    forterCustoemrFields?: any,
}


export type MerchantInfoResponse = {
    status: boolean,
    info: MerchantInfo
}


export type MerchantWarriorOptions = {
    environment: 'production' | 'development',
    api: string,
    fuse: string,
    middleware: string | ((request: APIRequest) => any),
    version: string,
    xcsrfToken: string,
    productName?: string,
    autoProcess?: boolean,
}

export const MerchantWarriorDefaultProdOptions: MerchantWarriorOptions = {
    environment: 'production',
    api: process.env.MW_API_URL ?? 'https://api.merchantwarrior.com',
    fuse: process.env.MW_FUSE_URL ?? 'https://secure.merchantwarrior.com',
    middleware: '/.well-known/MerchantWarrior.php',
    version: process.env.VERSION ?? 'latest',
    xcsrfToken: '',
}

export const MerchantWarriorDefaultDevOptions: MerchantWarriorOptions = {
    environment: 'development',
    api: process.env.MW_API_URL ?? 'https://base.merchantwarrior.com',
    fuse: process.env.MW_FUSE_URL ?? 'https://secure-test.merchantwarrior.com',
    middleware: '/.well-known/MerchantWarrior.php',
    version: 'latest',
    xcsrfToken: '',
}


// Payframe

export type PayframeOptions = {
    version?: string,
    threeDS?: boolean,
    submitUrl: string,
    styles?: {},
    tdsStyles?: {},
    acceptedCardTypes? : string,
    displayCVV?: boolean
}

export type PayframeObject = {
    accessToken?: string,
    payframeDivId: string,
    src: string,
    submitUrl: string,
    style?: object,
    acceptedCardTypes?: string,
    method?: string,
    displayCVV?: boolean,
}

export type PayframeTdsObject = {
    accessToken?: string,
    tdsDivId: string,
    submitUrl: string,
    style?: object,
}
